import React from "react";
import { SingLogo } from "./SingLogo";
import { SingDescription } from "./SingDescription";
import { SingContact } from "./SingContact";

const SingMainComponent: React.FC = () => {
  return (
    <div className="relative min-h-screen bg-white text-black">
      <div className="absolute top-0 left-0 p-4">
        <SingLogo />
      </div>
      <div className="flex-grow"></div>
      <div className="absolute bottom-0 left-0 p-4">
        <SingDescription />
        <SingContact />
      </div>
    </div>
  );
};

export default SingMainComponent;