import React from "react";

export const SingDescription: React.FC = () => {
 return (
  <div className="mt-72 text-2xl font-light tracking-wide leading-8 max-md:mt-10 max-md:max-w-full">
   SING is a blockchain IP tech company and rightsholder, selling music
   from major artists and catalogs as vinyl/digital hybrid releases to
   mass-market super-fans. We tokenize raw content into premium collectable
   SING Records™, unlocking revenue and consumer data as we lead the return
   to music ownership.
  </div>
 );
};