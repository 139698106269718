import React from "react";

export const SingLogo: React.FC = () => {
 return (
  <img
   loading="lazy"
   src="https://cdn.builder.io/api/v1/image/assets/c0c94f66eb504a95914b25d7421e5746/486735605fd2d1e351193e75caeeb9c9ebb938476345089e16c78ea6f218d6e4?apiKey=c0c94f66eb504a95914b25d7421e5746&"
   className="object-contain max-w-full aspect-[1.61] w-[241px]"
   alt="SING logo"
  />
 );
};