import React from "react";

export const SingContact: React.FC = () => {
 return (
  <div className="mt-7 text-3xl tracking-wider rotate-[2.251738169611258e-18rad]">
   <span className="text-2xl font-bold tracking-wide">CONTACT: </span>
   <a
    href="mailto:info@sing-inc.com"
    className="text-2xl font-light tracking-wide text-blue-600 underline"
    target="_blank"
    rel="noopener noreferrer"
   >
    info@sing-inc.com
   </a>
   <br />
   <br />
   <br />
  </div>
 );
};