// Code to render the SingComponent
import React from "react";
import "./App.css";
import SingMainComponent from "./components/SingMainComponent";

function App() {
  return <SingMainComponent />;
}

export default App;
